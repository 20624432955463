/* If you need to add @import statements, do so up here */
/* @import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,700,800&display=swap); */
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dynalight&display=swap');
@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-logo {
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  .overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #000000, #170b09);
    opacity: .4;
  } 
  .shape>* {
      -webkit-transform: scale(2);
      transform: scale(2);
  }
  .shape-top {
      -webkit-transform-origin: top center;
      transform-origin: top center;
  }
  .shape-flip-x {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
  }
  .logo-gradient-stop-1 {
      stop-color: #3688fc;
  }
  .logo-gradient-stop-2 {
      stop-color: #60a5fa;
  }
  .gallery-photo {
    margin-bottom: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    .inner {
      position: relative;
      display: block;
      border: 2px solid #fff;
    }
    img {
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .overlay {
      opacity: 0.3;
      transition: opacity ease-in-out .2s;
      text-align: center;
      z-index: 999;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .title {
      opacity: 1;
      transition: opacity ease-in-out .2s;
      position: absolute;
      text-align: center;
      padding: 0 15px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      color: #fff;
      text-shadow: 0 0 20px #000,0 2px 3px rgba(0,0,0,.5);
      font-weight: 700;
      font-size: 20px;
      @media (max-width: 769px) {
        opacity: 1;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      .overlay {
        opacity: 0;
      }
      .title {
        opacity: 1
      }
    }
  }
  .partner-card {
    @apply flex flex-col text-white text-center items-center justify-center bg-cover bg-center h-full relative hover:rounded-xl hover:scale-105 hover:z-20 transition duration-150;
    .inner {
      @apply block p-4 rounded-lg shadow-md hover:shadow-xl hover:transition-shadow duration-300 h-40;
    }
  }
}
/* :root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
} */

/* body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
} */

/* a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
} */
